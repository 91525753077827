














import { computed, defineComponent } from '@vue/composition-api';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';

import SetupRecurring from '@/components/wizard/steps/deployment/Recurring.vue';
import SetupBootstrap from '@/components/wizard/steps/deployment/Bootstrap.vue';
import AppWizardTitle from '@/components/wizard/forms/title/WizardTitle.vue';
import { useSetupWizardStore } from '@/stores/setup-wizard';

export default defineComponent({
  name: 'SetupDeployment',
  components: {
    AppExpansionPanel,
    SetupRecurring,
    SetupBootstrap,
    AppWizardTitle,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const panels = computed<number>({
      get() {
        return setupWizardStore.selectedDeploymentPanel || 0;
      },
      set(value) {
        setupWizardStore.selectDeploymentPanel(value);
      },
    });

    return {
      panels,
    };
  },
});
