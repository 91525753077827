









import { defineComponent, PropType } from '@vue/composition-api';

import MultipleInputs from '@/components/wizard/forms/inputs/MultipleInputs.vue';
import { useNewProjectsStore } from '@/stores/new-project';
import { DeployStepType } from '@/model/store';

export default defineComponent({
  name: 'SetupPostDeployment',
  components: {
    MultipleInputs,
  },
  props: {
    type: {
      type: Number as PropType<DeployStepType>,
      default: 0,
    },
    initial: {
      type: Array as PropType<string[]>,
      default: () => [''],
    },
  },
  setup(props) {
    const updatePostDeployment = (value: string[]) => {
      const store = useNewProjectsStore();
      store.updateDeployStep(props.type, { postDeployment: value });
    };

    return {
      updatePostDeployment,
    };
  },
});
