






















import * as _ from 'lodash';
import { defineComponent, ref, computed, PropType } from '@vue/composition-api';

function filterSuggestions(suggestions: string[], input: string): string[] {
  return suggestions.filter((suggestion) => {
    const index = suggestion.indexOf(input);
    return index > -1;
  });
}

const ownProps = ['value', 'suggestions'];

export default defineComponent({
  name: 'Autocomplete',
  props: {
    value: {
      type: String,
      default: '',
    },
    suggestions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['input', 'change'],
  setup(props, { attrs, emit }) {
    const tfProps = _.omit(attrs, ownProps);
    const input = ref(props.value);
    const textFieldProps = ref(tfProps);
    const showSuggestions = computed(() => filteredSuggestions.value?.length);
    const filteredSuggestions = computed(() => filterSuggestions(props.suggestions, input.value));
    const setInput = (value: string) => {
      input.value = value;
      emitInput();
    };
    const emitInput = () => {
      emit('input', input.value);
    };
    const emitChange = () => {
      emit('change', input.value);
    };

    return {
      input,
      textFieldProps,
      filteredSuggestions,
      showSuggestions,
      setInput,
      emitChange,
    };
  },
});
