



































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api';
import { v4 as uuid } from 'uuid';
import AppAutocomplete from '@/components/shared/Autocomplete.vue';
import { removeByUuid } from '@/utils';

export default defineComponent({
  name: 'MultipleInputs',
  components: {
    AppAutocomplete,
  },
  props: {
    initial: {
      type: Array as PropType<string[]>,
      default: () => [''],
    },
    textFieldProps: {
      type: Object,
      default: () => ({}),
    },
    autocomplete: Array as PropType<string[]>,
    uniqueAutocomplete: Boolean,
    label: String,
    addLabel: String,
    prefix: String,
    placeholder: String,
    isInformation: Boolean,
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { prefix, textFieldProps } = props;
    const autocomplete = props.autocomplete || [];
    const inputs = ref(
      (props.initial.length ? props.initial : ['']).map((value) => ({
        uuid: uuid(),
        value,
      }))
    );
    const suggestions = computed(() =>
      autocomplete.filter(
        (suggestion) => !props.uniqueAutocomplete || !inputs.value.map(({ value }) => value).includes(suggestion)
      )
    );
    const tfProps = ref({
      ...(prefix ? { prefix } : {}),
      ...textFieldProps,
    });

    const add = () => {
      inputs.value.push({
        uuid: uuid(),
        value: '',
      });
    };

    const remove = (uuid: string) => {
      removeByUuid(inputs.value, uuid);
      emitChange();
    };

    const emitChange = () => {
      const values = inputs.value.map(({ value }) => value);
      emit('change', values);
    };

    watch(
      inputs,
      () => {
        emitChange();
      },
      { deep: true }
    );

    return {
      inputs,
      suggestions,
      tfProps,
      add,
      remove,
      emitChange,
    };
  },
});
