








import { defineComponent } from '@vue/composition-api';

import SetupExcludes from '@/components/wizard/steps/deployment/forms/Excludes.vue';
import SetupPostDeployment from '@/components/wizard/steps/deployment/forms/PostDeployment.vue';
import { DeployStepType } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';

export default defineComponent({
  name: 'SetupBootstrap',
  components: {
    SetupExcludes,
    SetupPostDeployment,
  },
  setup() {
    const config = useNewProjectsStore().deployStep.bootstrapConfig;

    const excludes = config?.excludes;
    const postCommands = config?.remoteCommands.postDeployment;

    return {
      DeployStepType,
      excludes,
      postCommands,
    };
  },
});
